import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ArrowBotLeft, ArrowTopRight } from '../../../components/ui/icons';

export const ButtonIcon = styled.button`
  /* Increase clickable area without altering the visual icon size */
  padding: 1rem; /* This adds extra clickable space */
  min-width: 44px;
  min-height: 44px;

  /* Retain existing visual styling */
  position: relative; /* if needed for layout */
  z-index: 1;
  border: none;
  background: none;
  cursor: pointer !important;

  /* Add focus outline for accessibility */
  &:focus {
    outline: 3px solid #afcbe2;
    border-radius: 50%;
    outline-offset: 2px;
  }
`;

export const ArrowBotLeftIcon = styled(ArrowBotLeft)`
  color: blue;
`;

export const ArrowTopRightIcon = styled(ArrowTopRight)``;

export const SectionWrapper = styled.section<{
  padding: number;
  mobileTop: boolean;
  height?: string;
  ref: any;
}>`
  height: ${({ height }) => (height ? height : '100vh')};
  width: 100vw;
  padding: ${({ padding }) =>
    padding !== undefined ? `${padding}rem` : '7vw'};

  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${({ mobileTop }) => (mobileTop ? 'flex-start' : 'center')};
  min-height: 100vh;
  overflow: hidden;

  /* border: 6px solid #5a67d8;
  border: 6px solid transparent;
  border-image: linear-gradient(45deg, #5a67d8, #afcbe2) 1; */

  @media (min-width: 768px) {
    justify-content: center;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: blue; */
  z-index: 10; /* Make sure this is higher than your 3D content */
  pointer-events: all;
  height: 100%;
`;

export const Heading = styled.h1`
  position: relative;
  font-size: 5.5rem;
  color: #afcbe2;
  margin-top: 2rem;
  max-width: 40vw;
  @media (max-width: 768px) {
    font-size: 3.65rem;
    margin-top: 14vh;
    max-width: 60vw;
  }
`;

export const Highlight = styled.span`
  background-color: #dae8eb;
  background-color: #153147;
  padding: 0.25rem;
  font-style: italic;
`;

export const Paragraph = styled(motion.p)`
  font-size: 2.125rem;
  color: #4a5568;
  color: #a0aec0;
  color: #afcbe2;
  margin-top: 1rem;
  max-width: 45vw;
  line-height: 1.7;

  @media (max-width: 768px) {
    font-size: 1.85rem;
    max-width: 85vw;
  }
`;

export const Button = styled(motion.button)`
  background-color: #15409d;
  backdrop-filter: saturate(180%) blur(20px);
  background: transparent;
  color: #afcbe2;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  border: none;
  font-weight: bold;
  font-size: 1.625rem;
  margin-top: 1rem;
  cursor: pointer;
  border: 2px solid #afcbe2;

  visibility: visible; /* Reserve space in the layout without showing the button */
  opacity: 1; /* This will be handled by framer-motion for animation */

  @media (min-width: 768px) {
    margin-top: 4rem;
  }

  &:hover {
    scale: 1.05;
  }

  &:active {
    scale: 0.95;
  }
`;

export const SectionHeading = styled.h2`
  font-size: 3rem;
  /* font-weight: 700; */
  @media (min-width: 768px) {
    font-size: 3.125rem;
    max-width: 28rem;
  }
`;

export const SkillContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 16rem;
  }
`;

export const SkillTitle = styled(motion.h3)`
  font-size: 1.125rem;
  font-weight: 700;
  color: #e2e8f0;
`;

export const SkillLevelWrapper = styled.div`
  height: 0.5rem;
  width: 100%;
  background-color: #e2e8f0;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
`;

export const SkillLevel = styled(motion.div)`
  height: 100%;
  background-color: #5a67d8;
  border-radius: 0.5rem;
`;

export const FormWrapper = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.5);
  width: 24rem;
  max-width: 100%;
`;

export const Label = styled.label`
  font-weight: 500;
  color: #1a202c;
  display: block;
  margin-bottom: 0.25rem;
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  border: none;
  color: #1a202c;
  box-shadow: 0 0 0 1px #e2e8f0 inset;
  padding: 0.75rem;
  &::placeholder {
    color: #a0aec0;
  }
  &:focus {
    box-shadow: 0 0 0 2px #5a67d8 inset;
  }
`;

export const Textarea = styled.textarea`
  height: 8rem;
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  border: none;
  color: #1a202c;
  box-shadow: 0 0 0 1px #e2e8f0 inset;
  padding: 0.75rem;
  &::placeholder {
    color: #a0aec0;
  }
  &:focus {
    box-shadow: 0 0 0 2px #5a67d8 inset;
  }
`;

export const ValidationErrorMsg = styled.p`
  margin-top: 0.25rem;
  color: #e53e3e;
`;

export const SubmitButton = styled.button`
  background-color: #5a67d8;
  background-color: #afcbe2;
  color: #191e22;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: 1.125rem;
  margin-top: 1rem;
  cursor: pointer;
`;

export const SmallCaps = styled(motion.span)`
  font-size: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 0.35rem;
  color: #a0aec0;
  color: #5e6c85;
  color: #6c7c98;

  @media (min-width: 768px) {
    font-size: 1.05rem;
    margin-bottom: 1rem;
  }
`;
