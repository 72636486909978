import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import Col from '../../components/layout/Col';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

/**
 * NOTE:
 *  - We rename the interpolated prop to '$fadingOut' inside the animation prop.
 *  - We also set 'position: fixed; top: 0; left: 0;' so it covers the screen.
 */
const SplashContainer = styled.div<{ $fadingOut: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999; /* Ensures splash is on top */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--primary);
  color: var(--primary2);
  font-size: 1.4rem;

  /* We use the prop name '$fadingOut' here to match the styled prop above. */
  animation: ${({ $fadingOut }) => ($fadingOut ? fadeOut : '')} 1s ease-in-out;
`;

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressBar = styled.div`
  width: 300px;
  height: 10px;
  background-color: var(--primary);
  margin-top: 20px;
`;

const ProgressIndicator = styled.div<{ progress: number }>`
  width: ${({ progress }) => progress}%;
  height: 100%;
  background-color: var(--primary2);
  transition: width 0.3s ease-in-out;
`;

const Big = styled.div`
  font-size: 8.5rem;
  font-weight: bold;
`;

interface SplashProps {
  fadingOut: boolean;
}

const Splash: React.FC<SplashProps> = ({ fadingOut }) => {
  const [simulatedProgress, setSimulatedProgress] = useState(0);

  useEffect(() => {
    const startTime = Date.now();
    const duration = 3900; // 4 seconds

    const updateProgress = () => {
      const elapsedTime = Date.now() - startTime;
      const progress = Math.min((elapsedTime / duration) * 100, 100);
      setSimulatedProgress(progress);

      if (progress < 100) {
        const randomDelay = Math.random() * (200 - 50) + 50; // Random delay between 50ms and 200ms
        setTimeout(updateProgress, randomDelay);
      }
    };

    updateProgress();

    return () => {
      setSimulatedProgress(0); // Reset progress if component unmounts
    };
  }, []);

  return (
    <SplashContainer $fadingOut={fadingOut}>
      <Loader>
        <Col>
          Welcome to
          <Big>SOR SEO</Big>
        </Col>
        <div>Loading... {Math.round(simulatedProgress)}%</div>
        <ProgressBar>
          <ProgressIndicator progress={simulatedProgress} />
        </ProgressBar>
      </Loader>
    </SplashContainer>
  );
};

export default Splash;
