import styled from 'styled-components';
import { motion } from 'framer-motion';

/*
  ================================================================================
  THEME & GENERAL STYLES
  ================================================================================
*/
export const colors = {
  primaryBg: '#deeaf5',
  primaryText: '#3f6d93',
  secondaryText: '#555555',
  accent: '#0F4C81',
  accentLight: '#2f70af',
  highlight: '#234764',
  borderColor: '#dddddd',
};

export const breakpoints = {
  mobile: '(max-width: 480px)',
  tablet: '(max-width: 768px)',
  desktop: '(min-width: 769px)',
};

/*
  ================================================================================
  LAYOUT COMPONENTS
  - Structured to be highly responsive, professional, and visually sharp
  ================================================================================
*/

/* 1) PageContainer: Wraps entire content */
export const PageContainer = styled.div`
  margin: 0 auto;
  margin-top: 5rem;
  max-width: 1200px;
  padding: 1rem 4rem;
  background-color: ${colors.primaryBg};
  border-radius: 2rem;

  @media ${breakpoints.tablet} {
    padding: 0 1rem;
  }
`;

/* 2) HeroSection: Large, visually prominent top banner */
export const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  /* padding: 7rem 0 5rem 0; */
  min-height: 40vh;
  background: linear-gradient(
    135deg,
    ${colors.primaryBg} 0%,
    ${colors.primaryText} 100%
  );
  color: #ffffff;
  text-align: center;

  @media ${breakpoints.tablet} {
    padding: 5rem 1rem 3rem 1rem;
  }
`;

/* 3) HeroTitle: Main hero heading */
export const HeroTitle = styled.h1`
  font-size: 3.4rem;
  margin-bottom: 1rem;
  line-height: 1.2;

  @media ${breakpoints.tablet} {
    font-size: 2.8rem;
  }
`;

/* 4) HeroSubtitle: Subheading */
export const HeroSubtitle = styled.h4`
  font-size: 1.5rem;
  max-width: 60%;
  margin: 0 auto;
  color: #f4f4f4;
  line-height: 1.4;

  @media ${breakpoints.tablet} {
    max-width: 90%;
    font-size: 1.3rem;
  }
`;

/* 5) ContentSection: Wrapper for each content block */
export const ContentSection = styled.section`
  width: 100%;
  margin: 6.5rem 0;

  @media ${breakpoints.mobile} {
    margin: 3rem 0;
  }
`;

/* 6) SectionTitle: Large heading for sections */
export const SectionTitle = styled.h2`
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  color: ${colors.accent};
  text-align: left;

  @media ${breakpoints.tablet} {
    font-size: 2.1rem;
  }
`;

/* 7) SectionText: Paragraph text */
export const SectionText = styled.p`
  font-size: 1.6rem;
  color: ${colors.primaryText};
  margin-bottom: 1rem;
  line-height: 1.6;

  @media ${breakpoints.tablet} {
    font-size: 1.4rem;
  }
`;

/* 8) StyledList: Unordered list with custom bullet/hover effects */
export const StyledList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: 1rem;

  li {
    font-size: 1.6rem;
    margin-bottom: 0.6rem;
    position: relative;
    padding-left: 2rem;
    color: ${colors.primaryText};
    transition: all 0.3s ease;

    &::before {
      content: '•';
      position: absolute;
      left: 0;
      color: ${colors.accent};
      font-weight: bold;
    }

    &:hover {
      color: ${colors.highlight};
      transform: translateX(5px);
    }

    @media ${breakpoints.tablet} {
      font-size: 1.4rem;
    }
  }
`;

/* 9) QuoteContainer: Block for major quotes */
export const QuoteContainer = styled.div`
  width: 100%;
  background: #f7f7f7;
  border-left: 4px solid ${colors.accent};
  border-radius: 2rem;
  padding: 2rem;
  margin: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/* 10) QuoteText: Larger, italic text with an animation */
export const QuoteText = styled(motion.blockquote)`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.8rem;
  color: ${colors.primaryText};
  font-style: italic;
  max-width: 1100px;
  margin: 0 auto;
  line-height: 1;
  text-align: center;

  @media ${breakpoints.tablet} {
    font-size: 1.5rem;
  }

  &::before {
    content: '“';
    font-size: 3rem;
    color: ${colors.accent};
    vertical-align: top;
    margin-right: 0.5rem;
  }

  &::after {
    content: '”';
    font-size: 3rem;
    color: ${colors.accent};
    vertical-align: bottom;
    margin-left: 0.5rem;
  }
`;

/* 11) SectionDivider: A thin horizontal line to break up sections */
export const SectionDivider = styled.hr`
  border: none;
  border-top: 1px solid ${colors.borderColor};
  margin: 2rem 0;
  width: 100%;
`;

/* 12) ReferenceWrapper: Container for references at the bottom or inline */
export const ReferenceWrapper = styled.div`
  margin: 3rem 0;
  padding: 2rem;
  background-color: #fafafa;
  border: 1px solid ${colors.borderColor};
  border-radius: 4px;

  h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: ${colors.accent};
  }

  p {
    font-size: 1.4rem;
    color: ${colors.secondaryText};
    line-height: 1.4;
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-start;

    span {
      font-weight: bold;
      margin-right: 0.5rem;
      color: ${colors.primaryText};
    }

    @media ${breakpoints.tablet} {
      font-size: 1.2rem;
    }
  }
`;

/* 13) Footer: Optional page footer */
export const PageFooter = styled.footer`
  text-align: center;
  margin: 2rem 0;
  font-size: 1.2rem;
  padding: 1rem;
  padding-top: 0;
  color: ${colors.secondaryText};

  @media ${breakpoints.tablet} {
    font-size: 1rem;
  }
`;
