import { Outlet } from 'react-router-dom';
import { useAuthStore } from '../../../pages/auth/store/authStore';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Splash from '../../../pages/splash';
import { AppBody, AppMainLayout } from './Style';
import Header from '../../../features/ThreeJsHtmlExperience/Header';

/**
 * Simple fade-in container if you still want a quick fade for your main content,
 * though in this example, we keep the main content visible behind the splash from the start.
 * Feel free to remove if you do not need this fade.
 */
const TransitionContainer = styled.div`
  animation: fadeIn 1s ease-in;
  width: 100%;
`;

const Layout: React.FC = () => {
  const { loading } = useAuthStore();

  /**
   * We'll show the splash for 5s total:
   * - 4s of fully visible
   * - then 1s of fade out
   * - then remove it from the DOM
   */
  const [showSplash, setShowSplash] = useState(
    !sessionStorage.getItem('splashShown'),
  );
  const [fadingOut, setFadingOut] = useState(false);

  // Splash logic
  useEffect(() => {
    if (showSplash) {
      // Start fade-out after 4s
      const fadeTimer = setTimeout(() => {
        setFadingOut(true); // Tells the <Splash> component to play a fade-out animation
      }, 4000);

      // Unmount splash after 5s
      const removeTimer = setTimeout(() => {
        setShowSplash(false);
        sessionStorage.setItem('splashShown', 'true');
      }, 5000);

      return () => {
        clearTimeout(fadeTimer);
        clearTimeout(removeTimer);
      };
    }
  }, [showSplash]);

  /**
   * Show the Header after 10 seconds (arbitrary).
   * If you want the header immediately after the splash, reduce this or remove it entirely.
   */
  const [showHeader, setShowHeader] = useState(false);
  useEffect(() => {
    const headerTimer = setTimeout(() => setShowHeader(true), 10000);
    return () => clearTimeout(headerTimer);
  }, []);

  return (
    <AppBody>
      {/* Main content: always mounted (so the 3D scene can load behind the splash). */}
      {/* If 'showHeader' is false, we skip the Header for now. */}
      {showHeader && <Header />}

      <AppMainLayout>
        <TransitionContainer>
          <main>
            <Outlet />
          </main>
        </TransitionContainer>
      </AppMainLayout>

      {/* Splash is on top only while 'showSplash' is true. 
          'fadingOut' indicates the last 1s, so <Splash> can animate out. */}
      {showSplash && <Splash fadingOut={fadingOut} />}
    </AppBody>
  );
};

export default Layout;
