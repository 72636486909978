import Back from '../../../app/assets/img/backIcon.svg';
import IconWrapper from './style';

export const BackIcon = ({
  size = '24px',
  color = '#afcbe2',
  onClick,
}: {
  size?: string;
  color?: string;
  onClick?: () => void;
}) => {
  return (
    <IconWrapper
      size={size}
      onClick={onClick}
      fillColor={color}
      cursor="pointer"
    >
      <Back />
    </IconWrapper>
  );
};

export default BackIcon;
