import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  HeroSection,
  HeroTitle,
  HeroSubtitle,
  PageContainer,
  ContentSection,
  SectionTitle,
  SectionText,
  StyledList,
  QuoteContainer,
  QuoteText,
  ReferenceWrapper,
  PageFooter,
} from './Style';

const ABM2025Guide = () => {
  const { t } = useTranslation('abmGuide'); // typical usage for i18n in React

  return (
    <React.Fragment>
      {/* HERO SECTION */}
      <HeroSection>
        <HeroTitle>{t('hero.title')}</HeroTitle>
        <HeroSubtitle>{t('hero.subtitle')}</HeroSubtitle>
      </HeroSection>

      {/* PAGE BODY */}
      <PageContainer>
        {/* SECTION: INTRODUCTION */}
        <ContentSection>
          <SectionTitle>{t('sections.introduction.title')}</SectionTitle>
          <SectionText>{t('sections.introduction.paragraph1')}</SectionText>
        </ContentSection>

        {/* SECTION: LATEST STATISTICS */}
        <ContentSection>
          <SectionTitle>{t('sections.latestStats.title')}</SectionTitle>
          <SectionText>{t('sections.latestStats.paragraph1')}</SectionText>
          <StyledList>
            <li>{t('sections.latestStats.listItem1')}</li>
            <li>{t('sections.latestStats.listItem2')}</li>
            <li>{t('sections.latestStats.listItem3')}</li>
            <li>{t('sections.latestStats.listItem4')}</li>
          </StyledList>
        </ContentSection>

        {/* QUOTE #1 */}
        <QuoteContainer>
          <QuoteText
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            {t('quotes.quote1')}
          </QuoteText>
        </QuoteContainer>

        {/* SECTION: EMERGING TRENDS */}
        <ContentSection>
          <SectionTitle>{t('sections.emergingTrends.title')}</SectionTitle>
          <SectionText>{t('sections.emergingTrends.paragraph1')}</SectionText>
          <StyledList>
            <li>{t('sections.emergingTrends.listItem1')}</li>
            <li>{t('sections.emergingTrends.listItem2')}</li>
            <li>{t('sections.emergingTrends.listItem3')}</li>
            <li>{t('sections.emergingTrends.listItem4')}</li>
          </StyledList>
        </ContentSection>

        {/* QUOTE #2 */}
        <QuoteContainer>
          <QuoteText
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            {t('quotes.quote2')}
          </QuoteText>
        </QuoteContainer>

        {/* SECTION: SUCCESS STORIES */}
        <ContentSection>
          <SectionTitle>{t('sections.successStories.title')}</SectionTitle>
          <SectionText>{t('sections.successStories.paragraph1')}</SectionText>
          <StyledList>
            <li>{t('sections.successStories.listItem1')}</li>
            <li>{t('sections.successStories.listItem2')}</li>
            <li>{t('sections.successStories.listItem3')}</li>
          </StyledList>
        </ContentSection>

        {/* QUOTE #3 */}
        <QuoteContainer>
          <QuoteText
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            {t('quotes.quote3')}
          </QuoteText>
        </QuoteContainer>

        {/* SECTION: INNOVATIONS & TECH */}
        <ContentSection>
          <SectionTitle>{t('sections.innovations.title')}</SectionTitle>
          <SectionText>{t('sections.innovations.paragraph1')}</SectionText>
          <StyledList>
            <li>{t('sections.innovations.listItem1')}</li>
            <li>{t('sections.innovations.listItem2')}</li>
            <li>{t('sections.innovations.listItem3')}</li>
            <li>{t('sections.innovations.listItem4')}</li>
          </StyledList>
        </ContentSection>

        {/* SECTION: ABM VS. TRADITIONAL MARKETING */}
        <ContentSection>
          <SectionTitle>{t('sections.abmVsTraditional.title')}</SectionTitle>
          <SectionText>{t('sections.abmVsTraditional.paragraph1')}</SectionText>
          <StyledList>
            <li>{t('sections.abmVsTraditional.listItem1')}</li>
            <li>{t('sections.abmVsTraditional.listItem2')}</li>
            <li>{t('sections.abmVsTraditional.listItem3')}</li>
            <li>{t('sections.abmVsTraditional.listItem4')}</li>
          </StyledList>
        </ContentSection>

        {/* SECTION: CONCLUSION */}
        <ContentSection>
          <SectionTitle>{t('sections.conclusion.title')}</SectionTitle>
          <SectionText>{t('sections.conclusion.paragraph1')}</SectionText>
        </ContentSection>

        {/* SECTION: REFERENCES */}
        <ReferenceWrapper>
          <h3>{t('references.title')}</h3>
          <p>
            <span>[1]</span> {t('references.ref1')}
          </p>
          <p>
            <span>[2]</span> {t('references.ref2')}
          </p>
          <p>
            <span>[3]</span> {t('references.ref3')}
          </p>
          <p>
            <span>[4]</span> {t('references.ref4')}
          </p>
          <p>
            <span>[5]</span> {t('references.ref5')}
          </p>
          <p>
            <span>[6]</span> {t('references.ref6')}
          </p>
          <p>
            <span>[7]</span> {t('references.ref7')}
          </p>
          <p>
            <span>[8]</span> {t('references.ref8')}
          </p>
          <p>
            <span>[9]</span> {t('references.ref9')}
          </p>
          <p>
            <span>[10]</span> {t('references.ref10')}
          </p>
          <p>
            <span>[11]</span> {t('references.ref11')}
          </p>
          <p>
            <span>[12]</span> {t('references.ref12')}
          </p>
          <p>
            <span>[13]</span> {t('references.ref13')}
          </p>
          <p>
            <span>[14]</span> {t('references.ref14')}
          </p>
          <p>
            <span>[15]</span> {t('references.ref15')}
          </p>
          <p>
            <span>[16]</span> {t('references.ref16')}
          </p>
          <p>
            <span>[17]</span> {t('references.ref17')}
          </p>
          <p>
            <span>[18]</span> {t('references.ref18')}
          </p>
          <p>
            <span>[19]</span> {t('references.ref19')}
          </p>
          <p>
            <span>[20]</span> {t('references.ref20')}
          </p>
          <p>
            <span>[21]</span> {t('references.ref21')}
          </p>
          <p>
            <span>[22]</span> {t('references.ref22')}
          </p>
          <p>
            <span>[23]</span> {t('references.ref23')}
          </p>
        </ReferenceWrapper>

        {/* PAGE FOOTER */}
        <PageFooter>
          {t('footer.textPart1')} {new Date().getFullYear()}{' '}
          {t('footer.textPart2')}
        </PageFooter>
      </PageContainer>
    </React.Fragment>
  );
};

export default ABM2025Guide;
