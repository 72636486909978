import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

// Import slick-carousel CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Layout } from './Style';
import BackIcon from '../../../../components/ui/icons/BackIcon';

// Styled components
const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 2rem;
  margin-top: 5vh;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  color: ${({ theme }) => theme.primary};
  transition: color 0.2s ease-in-out;
  * {
    &:hover {
      color: white;
    }
  }
`;

const CarouselContainer = styled.div`
  margin-bottom: 3rem;

  button.slick-arrow:before {
    color: ${({ theme }) => theme.primary};
  }

  .slick-slide {
    outline: none;
  }
`;

const Slide = styled.div`
  position: relative;
`;

const SlideImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 1.2rem;
  object-fit: cover;
`;

const OverviewSection = styled.div`
  margin-top: 10vh;
  margin-bottom: 3rem;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin: 1rem 0;
`;

const Subtitle = styled.h2`
  font-size: 1.4rem;
  margin: 0.5rem 0;

  color: ${({ theme }) => theme.primary};
  text-transform: uppercase;
`;

const Description = styled.p`
  font-size: 1.85rem;
  line-height: 1.6;
  margin: 1rem 0;
  margin-top: 5vh;
`;

const ServicesSection = styled.div`
  margin-top: 2rem;
  text-align: left;
  margin-top: 5vh;

  h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    font-size: 1.4rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid #e0e0e0;
  }
`;

// New toggle styled components
const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const ToggleSwitch = styled.div`
  display: flex;
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 999px;
  overflow: hidden;
  width: fit-content;
`;

const ToggleOption = styled.div`
  font-size: 1.5rem;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  background: ${({ active, theme }) =>
    active ? theme.primary : 'transparent'};
  color: ${({ active, theme }) => (active ? 'white' : theme.primary)};
  transition:
    background 0.3s,
    color 0.3s;
`;

const SliderMobile = styled(Slider)`
  max-width: 30rem;
  margin: 0 auto;
  /* .slick-slide {
    outline: none;
  }

  .slick-arrow:before {
    color: ${({ theme }) => theme.primary};
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-dots {
    bottom: -2rem;
  }

  .slick-dots li button:before {
    color: ${({ theme }) => theme.primary};
  }

  .slick-dots li.slick-active button:before {
    color: ${({ theme }) => theme.primary};
  }

  .slick-slide img {
    border-radius: 1.2rem;
    object-fit: cover;
  } */
`;

// Main Component
const PortfolioItem = () => {
  const { t, i18n } = useTranslation('ourWork');
  const { slug } = useParams();
  const navigate = useNavigate();

  // Retrieve the projects from translation files and convert to an array
  const projectsTranslations = useMemo(
    () => t('projects', { returnObjects: true }),
    [t, i18n.language],
  );

  const projects = useMemo(
    () =>
      Object.keys(projectsTranslations).map((key) => ({
        ...projectsTranslations[key],
      })),
    [projectsTranslations],
  );

  // Find the project item based on the URL slug
  const matchedItem = useMemo(
    () => projects.find((item) => item.slug === slug),
    [projects, slug],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  if (!matchedItem) {
    return <p>{t('notFound', 'Portfolio item not found')}</p>;
  }

  // Destructure data from the matched project item
  const {
    title,
    title2,
    description,
    mainImage,
    images,
    highlights,
    mobileImages,
  } = matchedItem;

  // Settings for the main (desktop) carousel
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
  };

  // Settings for the mobile images carousel
  const mobileCarouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
  };

  const hasMobile = mobileImages && mobileImages.length > 0;
  const hasDesktop = (images && images.length > 0) || mainImage;
  const [activeSlider, setActiveSlider] = useState(
    hasDesktop ? 'desktop' : 'mobile',
  );

  return (
    <Layout>
      <Container>
        {/* Header with back button */}
        <Header>
          <BackButton onClick={() => navigate(t('navigateLink'))}>
            <BackIcon />
            &nbsp;{t('back', 'BACK')}
          </BackButton>
        </Header>

        {/* Toggle switch above the carousel */}
        {(hasDesktop || hasMobile) && (
          <ToggleContainer>
            {hasDesktop && hasMobile ? (
              <ToggleSwitch>
                <ToggleOption
                  active={activeSlider === 'desktop'}
                  onClick={() => setActiveSlider('desktop')}
                >
                  Desktop
                </ToggleOption>
                <ToggleOption
                  active={activeSlider === 'mobile'}
                  onClick={() => setActiveSlider('mobile')}
                >
                  Mobile
                </ToggleOption>
              </ToggleSwitch>
            ) : (
              <ToggleSwitch>
                <ToggleOption active>
                  {hasDesktop ? 'Desktop' : 'Mobile'}
                </ToggleOption>
              </ToggleSwitch>
            )}
          </ToggleContainer>
        )}

        {/* Conditional carousel: Desktop or Mobile */}
        <CarouselContainer>
          {activeSlider === 'desktop' ? (
            <Slider {...carouselSettings}>
              {images && images.length > 0 ? (
                images.map((img, index) => (
                  <Slide key={`desktop-slide-${index}`}>
                    <SlideImage src={img} alt={`Project slide ${index + 1}`} />
                  </Slide>
                ))
              ) : (
                <Slide>
                  <SlideImage src={mainImage} alt="Project main" />
                </Slide>
              )}
            </Slider>
          ) : (
            <SliderMobile {...mobileCarouselSettings}>
              {mobileImages &&
                mobileImages.map((img, index) => (
                  <Slide key={`mobile-slide-${index}`}>
                    <SlideImage
                      src={img}
                      alt={`Mobile view slide ${index + 1}`}
                    />
                  </Slide>
                ))}
            </SliderMobile>
          )}
        </CarouselContainer>

        {/* Project overview */}
        <OverviewSection>
          <Title>{title}</Title>
          <Subtitle>{title2}</Subtitle>
          <Description>{description}</Description>
          <ServicesSection>
            <h3>{t('services', 'Services')}:</h3>
            <ul>
              {highlights &&
                highlights.map((highlight, index) => (
                  <li key={`service-${index}`}>{highlight}</li>
                ))}
            </ul>
          </ServicesSection>
        </OverviewSection>
      </Container>
    </Layout>
  );
};

export default PortfolioItem;
